const initialState = {
    email: "",
    name: "",
    token: localStorage.getItem("token"),
  };
  const user = (state = initialState, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          email: action.data.email,
          name: action.data.name,
          token: action.data.token,
        };
      case "LOGOUT":
        return {
          email: "",
          name: "",
          token: "",
        };
  
      default:
        return state;
    }
  };
  export default user;