/*!
=========================================================
* BLK Design System React - v1.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { connect } from "react-redux";
import { setUser } from "action";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";

import bigChartData from "variables/charts.js";

function LandingPage(props) {
  const handlePayment = () => {
    let postData = {
      type: 3,
    };
    const SERVERURL = "https://icamp.ecell.org.in/api";

    // const SERVERURL = 'http://localhost:3000/api';

    const authToken = props.authToken;
    if (authToken != "undefined") {
      const data = {
        type: 1,
      };

      axios
        .post(`${SERVERURL}/auth/payment`, postData, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
        .then((res) => {
          if (res.status === 200) {
            // window.location.replace(res.data.url);
            swal("Payment closed", " ", "warning");
          } else if (res.status === 409) {
            swal(
              "You have already paid",
              "Invest this money in cryptocurrency ",
              "warning"
            );
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            swal("You have been logout", "please login", "warning");
            window.location.href = 'https://app.ecell.org.in/login';
          } else if (err.response.status === 402) {
            swal(
              "ICAMP IS ONLY FOR KIIT STUDENT",
              "If you are a Kiit student register with KIIT email ",
              "warning"
            );
            window.location.href('https://app.ecell.org.in/login');
          } else if (err.response.status === 409) {
            swal(
              "You have already paid",
              "Invest this money in cryptocurrency ",
              "warning"
            );
          } else {
            swal("Something went wrong", "server error", "error");
          }
        });
      console.log(props.authToken);
      // console.log(details);
    } else {
      swal();
    }
  };
  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png").default}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png").default}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          />
          <div className="content-center">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  Internship Camp <br />
                  <span className="text-white">2021</span>
                </h1>
                <p className="text-white mb-3">
                  The Internship camp, which is to be held soon, in the month of
                  May, is an initiative of KIIT Entrepreneurship Cell.
                </p>
                <div className="btn-wrapper mb-3">
                  <p className="category text-success d-inline">
                    From 14-16th May
                  </p>
                  <Button
                    className="btn-link"
                    color="success"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    <i className="tim-icons icon-minimal-right" />
                  </Button>
                </div>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href=" https://instagram.com/ecell_kiit?igshid=130bk62yi9s9v"
                      target="_blank"
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                    {/* <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-dribbble" />
                    </Button> */}
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.facebook.com/kiitecell/"
                      target="_blank"
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook" />
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/ICAMP.png").default}
                />
              </Col>
            </Row>
          </div>
        </div>
        <section className="section section-lg">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-trophy text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">336</CardTitle>
                                <p />
                                <p className="card-category">Startups</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats upper bg-default">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-coins text-white" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">1,986</CardTitle>
                                <p />
                                <p className="card-category">Internships</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-gift-2 text-info" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">3,699</CardTitle>
                                <p />
                                <p className="card-category">Registrations</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-credit-card text-success" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">100+</CardTitle>
                                <p />
                                <p className="card-category">Companies</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h1>About</h1>
                    <p>
                      KIIT Entrepreneurship Cell takes pride in providing this
                      platform for the Sixth time to all our students! Keeping
                      in mind the last year’s success, our target is to bring in
                      150+ corporates and start -ups, from different regions and
                      create 550+ internship opportunities for students. The
                      Internship Camp is supported by KIIT Training and
                      Placement cell, KIIT TnP and Technology Business
                      Incubator, KIIT-TBI, which is also one of the largest
                      incubation centres in Asia.
                    </p>
                    <br />
                    <p>
                      The Internship Camp, which is to be held soon, in the
                      month of May, is an initiative of KIIT Entrepreneurship
                      Cell which aims to provide internship opportunities to
                      undergraduate students from the field of engineering,
                      biotechnology, management, design and law for students not
                      only from KIIT University, but for students all around
                      Bhubaneswar as well.
                    </p>
                    <br />
                    {/* <a
                      className="font-weight-bold text-info mt-5"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Show all{" "}
                      <i className="tim-icons icon-minimal-right text-info" />
                    </a> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="section section-lg">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path5.png").default}
          />
          <img
            alt="..."
            className="path3"
            src={require("assets/img/path2.png").default}
          />

          {/* <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center">Your best benefit</h1>
                <Row className="row-grid justify-content-center">
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-primary">
                        <i className="tim-icons icon-money-coins" />
                      </div>
                      <h4 className="info-title">Low Commission</h4>
                      <hr className="line-primary" />
                      <p>
                        Divide details about your work into parts. Write a few
                        lines about each one. A paragraph describing a feature
                        will.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-warning">
                        <i className="tim-icons icon-chart-pie-36" />
                      </div>
                      <h4 className="info-title">High Incomes</h4>
                      <hr className="line-warning" />
                      <p>
                        Divide details about your product or agency work into
                        parts. Write a few lines about each one. A paragraph
                        describing feature will be a feature.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <h4 className="info-title">Verified People</h4>
                      <hr className="line-success" />
                      <p>
                        Divide details about your product or agency work into
                        parts. Write a few lines about each one. A paragraph
                        describing be enough.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container> */}
        </section>
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 mb-50 text-center">
              <h1
                className="title"
                style={{ textAlign: "center", fontSize: "50px" }}
              >
                Past Companies
              </h1>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div
          className="container"
          style={{ minWidth: "100%", textAlign: "center" }}
        >
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 block-sponsor">
              <div className="image" style={{ padding: "2rem" }}>
                <img src={require("assets/img/oyo.png").default} />
              </div>
            </div>
            <div className="col-md-2 col-sm-2 block-sponsor">
              <div className="image">
                <img src={require("assets/img/mycaptain.png").default} />
              </div>
            </div>
            <div className="col-md-2 col-sm-2 block-sponsor">
              <div className="image">
                <img
                  src={require("assets/img/bepec.png").default}
                  style={{ width: "27rem" }}
                />
              </div>
            </div>
            <div className="col-md-2 col-sm-2 block-sponsor">
              <div className="image">
                <img
                  src={require("assets/img/arduino.png").default}
                  style={{ padding: "2rem 1rem", width: "80%" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-2 block-sponsor">
              <div className="image" style={{ padding: "5rem 1rem" }}>
                <img src={require("assets/img/Tech.png").default} />
              </div>
            </div>
            <div className="col-md-2 col-sm-2 block-sponsor">
              <div className="image">
                <img
                  src={require("assets/img/unschool.png").default}
                  style={{ padding: "1rem" }}
                />
              </div>
            </div>
            <div className="col-md-2 col-sm-2 block-sponsor">
              <div className="image">
                <img
                  src={require("assets/img/coviam.png").default}
                  style={{ padding: "5rem 1rem" }}
                />
              </div>
            </div>
          </div>
        </div>
        <section className="section section-lg section-safe">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path5.png").default}
          />
          <Container>
            <Row className="row-grid justify-content-between">
              <Col md="5">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("assets/img/bg.jpeg").default}
                />
                <Card className="card-stats bg-danger">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">3369</CardTitle>
                        <p className="card-category text-white">
                          Registrations
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-info">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">1986</CardTitle>
                        <p className="card-category text-white">Internships</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-default">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">336</CardTitle>
                        <p className="card-category text-white">Startups</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <div className="px-md-5">
                  <hr className="line-success" />
                  <h3>Mission & Vision</h3>
                  <p>
                    We believe that emerging economies like India need the
                    passion and drive of the new and young class of
                    entrepreneurs, who are worthy and target their efforts on
                    innovative ways to technologically address the genuine needs
                    of millions of people. Motivated by this vision, KIIT E-Cell
                    aims to foster entrepreneurship culture among the students
                    and establishing necessary support systems for aspiring
                    entrepreneurs with East India's largest inbuilt KIIT
                    Technology Business Incubator, KIIT TBI and KIIT Training
                    and Placement cell KIIT TnP . This is achieved through a
                    number of activities such as discussion forums, speaker
                    sessions, B-plan competitions, summer internships and
                    webinars by eminent international personalities which
                    provide the tools to morph an idea into an implementable
                    plans.
                  </p>
                  {/* <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-vector" />
                        </div>
                        <div className="ml-3">
                          <h6>Carefully crafted components</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-tap-02" />
                        </div>
                        <div className="ml-3">
                          <h6>Amazing page examples</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <div className="ml-3">
                          <h6>Super friendly support team</h6>
                        </div>
                      </div>
                    </li>
                  </ul> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          <Col md="12">
            <Card className="card-chart card-plain">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <hr className="line-info" />
                    <h5 className="card-category">Through the years</h5>
                    <CardTitle tag="h2">Internships Offered</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={bigChartData.data}
                    options={bigChartData.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </section>
        <section className="section section-lg section-coins">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path3.png").default}
          />
          <Container>
            <Row>
              <Col md="4">
                <hr className="line-info" />
                <h1>
                  Pricing for <span className="text-info">ICAMP 2021</span>
                </h1>
              </Col>
            </Row>
            <Row>
              {/* <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/bitcoin.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Light Coin</h4>
                        <span>Plan</span>
                        <hr className="line-primary" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>50 messages</ListGroupItem>
                        <ListGroupItem>100 emails</ListGroupItem>
                        <ListGroupItem>24/7 Support</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-simple" color="primary">
                      Get plan
                    </Button>
                  </CardFooter>
                </Card>
              </Col> */}
              <Col md="4" style={{ textAlign: "center" }}>
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/etherum.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Price</h4>
                        <span>Rs 349</span>
                        <hr className="line-success" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Internship Camp</ListGroupItem>
                        <ListGroupItem>Only for KIIT Students</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  {/* <CardFooter className="text-center">
                    {localStorage.getItem("token") == undefined ? (
                      // <Button className="btn-simple" color="success">
                      //   <Link to="/">Get Tickets</Link>
                      // </Button>
                      <Button
                        className="btn-simple"
                        color="success"
                        onClick={() => {
                          swal("Payment closed", " ", "warning");
                        }}
                      >
                        Get Tickets
                      </Button>
                    ) : (
                      <Button
                        className="btn-simple"
                        color="success"
                        onClick={
                          handlePayment
                          //   () => {
                          //   swal(
                          //     "Payment will be live soon",
                          //     "Stay tuned on our social media handles",
                          //     "warning"
                          //   );
                          // }
                        }
                      >
                        Get Tickets
                      </Button>
                    )}
                  </CardFooter> */}
                </Card>
              </Col>
              {/* <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/ripp.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Bright Coin</h4>
                        <span>Plan</span>
                        <hr className="line-info" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>350 messages</ListGroupItem>
                        <ListGroupItem>10K emails</ListGroupItem>
                        <ListGroupItem>24/7 Support</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-simple" color="info">
                      Get plan
                    </Button>
                  </CardFooter>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});
const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
