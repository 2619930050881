import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardImg,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

// core components
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import Footer from 'components/Footer/Footer.js';

export default function RegisterPage() {
	const [squares1to6, setSquares1to6] = React.useState('');
	const [squares7and8, setSquares7and8] = React.useState('');
	const [fullNameFocus, setFullNameFocus] = React.useState(false);
	const [emailFocus, setEmailFocus] = React.useState(false);
	const [passwordFocus, setPasswordFocus] = React.useState(false);
	const [phoneFocus, setPhoneFocus] = React.useState(false);
	const [rollFocus, setRollFocus] = React.useState(false);

	const [checked, setCheck] = useState(false);
	const updateCheck = () => {
		setCheck(!checked);
	};
	const postData = {
		name: '',
		email: '',
		password: '',
		no: NaN,
		roll: NaN,
		gender: 'F',
	};
	const [name, setname] = useState('');

	const updatename = (e) => {
		setname(e.target.value);
	};
	const [email, setemail] = useState('');
	const updateemail = (e) => {
		setemail(e.target.value);
	};
	const [phone, setphone] = useState('');
	const [gender, setGender] = useState('');
	const updateGender = (e) => {
		setGender(e.target.value);
	};
	const updatephone = (e) => {
		setphone(e.target.value);
	};
	const [password, setpassword] = useState('');
	const updatepassword = (e) => {
		setpassword(e.target.value);
	};
	const [roll, setroll] = useState('');
	const updateroll = (e) => {
		setroll(e.target.value);
	};
	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		postData.name = name;
		postData.email = email;
		postData.no = phone;
		postData.password = password;
		postData.roll = roll;
		if (email === '' || name === '' || password === '') {
			swal('Please give valid input', 'validation fail', 'warning');
		} else if (password.length < 8) {
			swal('please enter a strong password', 'validation failed', 'warning');
		} else if (!validateEmail(email)) {
			swal('please enter a valid email', 'validation failed', 'warning');
		} else if (phone.length !== 10) {
			swal('please enter a valid phone no', 'validation failed', 'warning');
		} else if (roll.length < 5) {
			console.log(roll);
			swal('please enter a valid roll no', 'validation failed', 'warning');
		} else {
			axios
				.post(`https://icamp.ecell.org.in/api/auth/signup`, postData)
				.then((response) => {
					console.log(response);
					if (response.status === 200) {
						swal(
							'Registration successful',
							'Please verify your email',
							'success'
						);

						//alert("Registered successfully");
					} else if (response.status === 409) {
						swal('You have already registered', 'Please Login', 'warning');
					} else if (response.status === 500) {
						swal('Something went wrong', 'Please try later', 'error');
					} else if (response.status === 402) {
						swal(
							'Icamp is only for kiit student',
							'if you are a kiit student please use kiit email',
							'warning'
						);
					}
				})
				.catch((err) => {
					if (err.response.status === 400) {
						swal('Something went wrong', 'Please try later', 'error');
					} else if (err.response.status === 409) {
						swal('You have already registered', 'Please Login', 'warning');
					} else if (err.response.status === 500) {
						swal('Something went wrong', 'Please try later', 'error');
					} else if (err.response.status === 401) {
						swal(
							'Icamp is only for kiit student',
							'if you are a kiit student please use kiit email',
							'warning'
						);
					}
				});
			setname('');
			setemail('');
			setphone('');
			setpassword('');
			setroll('');
			setCheck(false);
		}
	};
	React.useEffect(() => {
		document.body.classList.toggle('register-page');
		document.documentElement.addEventListener('mousemove', followCursor);
		// Specify how to clean up after this effect:
		return function cleanup() {
			document.body.classList.toggle('register-page');
			document.documentElement.removeEventListener('mousemove', followCursor);
		};
	}, []);
	const followCursor = (event) => {
		let posX = event.clientX - window.innerWidth / 2;
		let posY = event.clientY - window.innerWidth / 6;
		setSquares1to6(
			'perspective(500px) rotateY(' +
				posX * 0.05 +
				'deg) rotateX(' +
				posY * -0.05 +
				'deg)'
		);
		setSquares7and8(
			'perspective(500px) rotateY(' +
				posX * 0.02 +
				'deg) rotateX(' +
				posY * -0.02 +
				'deg)'
		);
	};
	return (
		<>
			<ExamplesNavbar />
			<div className="wrapper">
				<div className="page-header">
					<div className="page-header-image" />
					<div className="content">
						<Container>
							<Row>
								<Col className="offset-lg-0 offset-md-3" lg="5" md="6">
									<div
										className="square square-7"
										id="square7"
										style={{ transform: squares7and8 }}
									/>
									<div
										className="square square-8"
										id="square8"
										style={{ transform: squares7and8 }}
									/>
									<Card className="card-register">
										<CardHeader>
											<CardImg
												alt="..."
												src={require('assets/img/square-purple-1.png').default}
											/>
											<CardTitle tag="h4">Register</CardTitle>
										</CardHeader>
										<CardBody>
											<Form className="form">
												<InputGroup
													className={classnames({
														'input-group-focus': fullNameFocus,
													})}
												>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="tim-icons icon-single-02" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														required
														placeholder="Full Name"
														type="text"
														onFocus={(e) => setFullNameFocus(true)}
														onBlur={(e) => setFullNameFocus(false)}
														value={name}
														onChange={updatename}
													/>
												</InputGroup>
												<InputGroup
													className={classnames({
														'input-group-focus': emailFocus,
													})}
												>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="tim-icons icon-email-85" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														required
														placeholder="Email"
														type="text"
														onFocus={(e) => setEmailFocus(true)}
														onBlur={(e) => setEmailFocus(false)}
														value={email}
														onChange={updateemail}
													/>
												</InputGroup>
												<InputGroup
													className={classnames({
														'input-group-focus': phoneFocus,
													})}
												>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i class="fa fa-phone" aria-hidden="true" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														required
														placeholder="Mobile no."
														type="text"
														onFocus={(e) => setPhoneFocus(true)}
														onBlur={(e) => setPhoneFocus(false)}
														value={phone}
														onChange={updatephone}
													/>
												</InputGroup>
												<InputGroup
													className={classnames({
														'input-group-focus': rollFocus,
													})}
												>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i class="fa fa-info" aria-hidden="true"></i>
														</InputGroupText>
													</InputGroupAddon>
													<Input
														required
														placeholder="Roll no."
														type="text"
														onFocus={(e) => setRollFocus(true)}
														onBlur={(e) => setRollFocus(false)}
														value={roll}
														onChange={updateroll}
													/>
												</InputGroup>
												<InputGroup
													className={classnames({
														'input-group-focus': passwordFocus,
													})}
												>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="tim-icons icon-lock-circle" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														required
														placeholder="Password"
														type="password"
														onFocus={(e) => setPasswordFocus(true)}
														onBlur={(e) => setPasswordFocus(false)}
														value={password}
														onChange={updatepassword}
													/>
												</InputGroup>
												{/* <FormGroup check className="text-left">
                          <Label check>
                            <Input type="checkbox" onClick={updateCheck} />
                            <span className="form-check-sign" />I agree to the{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              terms and conditions
                            </a>
                            .
                          </Label>
                        </FormGroup> */}
											</Form>
										</CardBody>
										<CardFooter>
											<Button
												className="btn-round"
												color="primary"
												size="lg"
												// disabled={checked ? false : true}
												onClick={handleSubmit}
											>
												Register
											</Button>
										</CardFooter>
									</Card>
								</Col>
							</Row>
							<div className="register-bg" />
							<div
								className="square square-1"
								id="square1"
								style={{ transform: squares1to6 }}
							/>
							<div
								className="square square-2"
								id="square2"
								style={{ transform: squares1to6 }}
							/>
							<div
								className="square square-3"
								id="square3"
								style={{ transform: squares1to6 }}
							/>
							<div
								className="square square-4"
								id="square4"
								style={{ transform: squares1to6 }}
							/>
							<div
								className="square square-5"
								id="square5"
								style={{ transform: squares1to6 }}
							/>
							<div
								className="square square-6"
								id="square6"
								style={{ transform: squares1to6 }}
							/>
						</Container>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}
