/*!


* Coded by Kiit ecell

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
	Button,
	Collapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
} from 'reactstrap';

export default function ExamplesNavbar() {
	const [collapseOpen, setCollapseOpen] = React.useState(false);
	const [collapseOut, setCollapseOut] = React.useState('');
	const [color, setColor] = React.useState('navbar-transparent');
	React.useEffect(() => {
		window.addEventListener('scroll', changeColor);
		return function cleanup() {
			window.removeEventListener('scroll', changeColor);
		};
	}, []);
	const changeColor = () => {
		if (
			document.documentElement.scrollTop > 99 ||
			document.body.scrollTop > 99
		) {
			setColor('bg-info');
		} else if (
			document.documentElement.scrollTop < 100 ||
			document.body.scrollTop < 100
		) {
			setColor('navbar-transparent');
		}
	};
	const toggleCollapse = () => {
		document.documentElement.classList.toggle('nav-open');
		setCollapseOpen(!collapseOpen);
	};
	const onCollapseExiting = () => {
		setCollapseOut('collapsing-out');
	};
	const onCollapseExited = () => {
		setCollapseOut('');
	};

	// const isLoggedIn = () => {
	//   if (localStorage.getItem("token").length !== 0) {
	//     console.log(localStorage.getItem("token"));
	//   }
	// };

	// isLoggedIn();
	return (
		<Navbar className={'fixed-top ' + color} color-on-scroll="100" expand="lg">
			<Container>
				<div className="navbar-translate">
					<NavbarBrand to="/" id="navbar-brand" tag={Link}>
						{/* <span>BLK• </span> */}
						<img
							style={{ height: '75px', width: '150px', marginTop: '-15px' }}
							src={require('assets/img/icampCompWhite.png').default}
						/>
					</NavbarBrand>
					<UncontrolledTooltip placement="bottom" target="navbar-brand">
						Internship Camp 2021
						{console.log('Yo')}
					</UncontrolledTooltip>
					<button
						aria-expanded={collapseOpen}
						className="navbar-toggler navbar-toggler"
						onClick={toggleCollapse}
					>
						<span className="navbar-toggler-bar bar1" />
						<span className="navbar-toggler-bar bar2" />
						<span className="navbar-toggler-bar bar3" />
					</button>
				</div>
				<Collapse
					className={'justify-content-end ' + collapseOut}
					navbar
					isOpen={collapseOpen}
					onExiting={onCollapseExiting}
					onExited={onCollapseExited}
				>
					<div className="navbar-collapse-header">
						<Row>
							<Col className="collapse-brand" xs="6">
								<a href="#pablo" onClick={(e) => e.preventDefault()}>
									<img
										style={{
											height: '75px',
											width: '150px',
											marginTop: '-15px',
										}}
										src={require('assets/img/icampCompWhite.png').default}
									/>
								</a>
							</Col>
							<Col className="collapse-close text-right" xs="6">
								<button
									aria-expanded={collapseOpen}
									className="navbar-toggler"
									onClick={toggleCollapse}
								>
									<i className="tim-icons icon-simple-remove" />
								</button>
							</Col>
						</Row>
					</div>
					<Nav navbar>
						<NavItem className="p-0">
							{/* <NavLink tag={Link}
                to="/register-page"
              > */}
							<a href="https://app.ecell.org.in/">
								<p
									className="d-lg-none d-xl-none"
									style={{
										backgroundColor: '#E14ECA',
										padding: '8px',
										borderRadius: '5px',
									}}
								>
									Dashboard
								</p>
							</a>
							{/* </NavLink> */}
						</NavItem>
						<NavItem className="p-0">
							<NavLink
								data-placement="bottom"
								href="https://www.facebook.com/kiitecell/"
								rel="noopener noreferrer"
								target="_blank"
								title="Like us on Facebook"
							>
								<i className="fab fa-facebook-square" />
								<p className="d-lg-none d-xl-none">Facebook</p>
							</NavLink>
						</NavItem>
						<NavItem className="p-0">
							<NavLink
								data-placement="bottom"
								href="https://instagram.com/ecell_kiit?igshid=130bk62yi9s9v"
								rel="noopener noreferrer"
								target="_blank"
								title="Follow us on Instagram"
							>
								<i className="fab fa-instagram" />
								<p className="d-lg-none d-xl-none">Instagram</p>
							</NavLink>
						</NavItem>
						<NavItem>
							<a
								className="nav-link d-none d-lg-block btn btn-block btn-primary"
								href="https://app.ecell.org.in/dashboard"
							>
								<i className="tim-icons icon-spaceship" /> Dashboard
							</a>
						</NavItem>

						{localStorage.getItem('token') == undefined ? (
							<>
								<NavItem>
									{/* <NavLink tag={Link} to="https://app.ecell.org.in/dashboard">
										Login
									</NavLink> */}
									<a href="https://app.ecell.org.in/dashboard">Login</a>
								</NavItem>
							</>
						) : (
							<NavItem>
								<Link
									className="nav-link d-none d-lg-block"
									color="primary"
									tag={Link}
									to="/"
									onClick={() => {
										localStorage.removeItem('token');
									}}
								>
									LogOut
								</Link>
							</NavItem>
						)}
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	);
}
