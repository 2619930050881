/*!

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'assets/css/nucleo-icons.css';
import 'assets/scss/blk-design-system-react.scss?v=1.2.0';
import 'assets/demo/demo.css';

import Index from 'views/Index.js';
import LandingPage from 'views/examples/LandingPage.js';
import RegisterPage from 'views/examples/RegisterPage.js';
import PaymentForm from 'views/examples/PaymentForm';
import ProfilePage from 'views/examples/ProfilePage.js';
import Login from 'views/examples/Login';
import store from './store';
import { Provider } from 'react-redux';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Switch>
				{/* <Route path="/components" render={(props) => <Index {...props} />} /> */}

				<Route
					path="/register-page"
					render={(props) => <RegisterPage {...props} />}
				/>
				{/* <Route
					path="/payment-form"
					render={(props) => <PaymentForm {...props} />}
				/>  */}
				{/* <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      /> */}
				<Route path="/login" render={(props) => <Login {...props} />} />

				<Redirect from="/landing-page" to="/" />
				<Route path="/" render={(props) => <LandingPage {...props} />} />
			</Switch>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
