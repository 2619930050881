/*!


*/
import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import classnames from "classnames";
import { connect } from "react-redux";
// import swal from "@sweetalert/with-react";
import { setUser } from "action";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
const SERVERURL = "https://ksk.ecell.org.in/api";

function Login(props) {
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [rollFocus, setRollFocus] = React.useState(false);

  const [checked, setCheck] = useState(true);
  const updateCheck = () => {
    setCheck(!checked);
  };
  //   const postData = {
  //     email: "",
  //     password: "",
  //     //match.params.ref_id
  //   };
  const [data, setData] = React.useState({
    email: "",
    password: "",
  });

  // const updatename = (e) => {
  //   setname(e.target.value);
  // };

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const postData = (event) => {
    event.preventDefault();
    // console.log(SERVERURL);
    console.log(data);
    axios
      .post(`${SERVERURL}/auth/login`, data)
      .then((response) => {
        if (response.status === 200) {
          //verfication success
          // <loggedIn.Provider value={true}></loggedIn.Provider>;
          const ndata = {
            email: data.email,
            name: response.data.name,
            token: response.data.token,
          };
          localStorage.setItem("token", response.data.token);
          props.setUser(ndata);

          setTimeout(function () {
            swal("Success", "Login Successful", "success");
          }, 50);
          setTimeout(function () {
            window.location.href = "/";
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          swal(
            "An Email has been sent to you.",
            "please verify your email",
            "warning"
          );
        } else if (err.response.status === 400) {
          swal(
            "Registration not done",
            "please register and then login",
            "warning"
          );
        } else if (err.response.status === 401) {
          swal("Password does not match", "", "warning");
        } else if (err.response.status === 404) {
          swal("email not found", "", "warning");
        } else if (err.response.status === 402) {
          swal(
            "Icamp is only for kiit student",
            "if you are a kiit student please use kiit email",
            "warning"
          );
        } else if (err.response.status === 410) {
          swal("Access Denied", "Payment not done", "warning");
        } else {
          swal("Server Error");
        }
      });
  };
  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    );
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png").default}
                      />
                      <CardTitle tag="h4"> login</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form">
                        <InputGroup
                          className={classnames({
                            "input-group-focus": emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            onFocus={(e) => setEmailFocus(true)}
                            onBlur={(e) => setEmailFocus(false)}
                            value={data.email}
                            onChange={(e) =>
                              setData({ ...data, email: e.target.value })
                            }
                          />
                        </InputGroup>

                        <InputGroup
                          className={classnames({
                            "input-group-focus": passwordFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                            value={data.password}
                            onChange={(e) =>
                              setData({ ...data, password: e.target.value })
                            }
                          />
                        </InputGroup>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className="btn-round"
                        color="primary"
                        size="lg"
                        disabled={checked ? false : true}
                        onClick={postData}
                      >
                        Login
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <div className="register-bg" />
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
